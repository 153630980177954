import { post } from '@/http/http'

// [充值]查询
export const marketGet = p => post('api/v1.0/marketeinfo/markete_topup_get', p)

// [充值]查询【所有】
export const marketGetAll = p => post('api/v1.0/marketeinfo/markete_topup_all_get', p)

// [充值]添加
export const marketAdd = p => post('api/v1.0/marketeinfo/markete_topup_add', p)

// [充值]编辑
export const marketUpdate = p => post('api/v1.0/marketeinfo/markete_topup_update', p)

// [充值]删除
export const marketDelete = p => post('api/v1.0/marketeinfo/markete_topup_delete', p)

// [充值]排序(上移，下移)
export const marketOper = p => post('api/v1.0/marketeinfo/marketetopup_oper', p)

// [充值]是否展示
export const marketIsOK = p => post('api/v1.0/marketeinfo/marketetopup_isok', p)

// [充值][匹配抵扣券]查询
/* export const marketveGet = p => post('api/v1.0/marketeinfo/markete_topup_as_ded_get', p) */
export const marketveGet = p => post('api/v2.0/marketeinfo/markete_topup_as_ded_get', p)

// [充值][匹配抵扣券]添加
export const marketveAdd = p => post('api/v1.0/marketeinfo/markete_topup_as_ded_add', p)

// [充值][匹配抵扣券]添加查询
export const marketveadGet = p => post('api/v1.0/marketeinfo/markete_topup_as_ded_add_get', p)

// [充值][匹配抵扣券]移除
export const marketveDelete = p => post('api/v1.0/marketeinfo/markete_topup_as_ded_delete', p)

// [充值][匹配抵扣券]赠送方式-数量
export const marketReNumGet = p => post('api/v1.0/marketeinfo/markete_topup_choose_get', p)

// [充值][匹配抵扣券]赠送方式-列表下拉
export const marketReNumList = p => post('api/v1.0/marketeinfo/markete_topup_choose_down', p)

// [充值][匹配抵扣券]赠送方式-修改
export const marketReNumEdit = p => post('api/v1.0/marketeinfo/markete_topup_choose_update', p)

// [充值][匹配抵扣券]保存数量-修改
export const marketNumSave = p => post('api/v2.0/marketeinfo/markete_topup_as_ded_num', p)

/* 套餐卡分组 */
// [套餐卡分组]一级查询
export const markgrouGet = p => post('api/v1.0/marketeinfo/markete_group_get', p)

// [套餐卡分组]二级查询
export const marklowGet = p => post('api/v1.0/marketeinfo/markete_group_low', p)

// [套餐卡分组]添加
export const markgrouAdd = p => post('api/v1.0/marketeinfo/markete_group_add', p)

// [套餐卡分组]编辑
export const markgrouUpdate = p => post('api/v1.0/marketeinfo/markete_group_update', p)

// [套餐卡分组]删除
export const markgrouDelete = p => post('api/v1.0/marketeinfo/markete_group_delete', p)

// [套餐卡分组]展示/不展示
export const markgrouIsok = p => post('api/v1.0/marketeinfo/markete_group_isok', p)

// [套餐卡分组]排序
export const marketeOper = p => post('api/v1.0/marketeinfo/markete_oper', p)

/* 套餐卡 */
// [套餐卡]列表(查询)
export const markcardGet = p => post('api/v1.0/marketeinfo/markete_card_get', p)

// [套餐卡]信息添加
export const markcardAdd = p => post('api/v1.0/marketeinfo/markete_card_add', p)

// [套餐卡]信息编辑
export const markcardUpdate = p => post('api/v1.0/marketeinfo/markete_card_update', p)

// [套餐卡]信息删除
export const markcardDelete = p => post('api/v1.0/marketeinfo/markete_card_delete', p)

// [套餐卡]一级分类(下拉)
export const markgroDown = p => post('api/v1.0/marketeinfo/markete_group_get_down', p)

// [套餐卡]二级分类(下拉)
export const markgroLow = p => post('api/v1.0/marketeinfo/markete_group2_down', p)

// [套餐卡]信息查询(ID)
export const markidGet = p => post('api/v1.0/marketeinfo/markete_card_get_id', p)

// [套餐卡][服务门店]查询
export const markshopGet = p => post('api/v1.0/marketeinfo/markete_card_shop_get', p)

// [套餐卡][服务门店]编辑
export const markshopUpdate = p => post('api/v1.0/marketeinfo/markete_card_shop_update', p)

// [套餐卡][所有服务]查询
export const serveGet = p => post('api/v1.0/marketeinfo/markete_card_serve', p)

// [套餐卡][关联服务]查询
export const markserveGet = p => post('api/v1.0/marketeinfo/markete_card_serve_get', p)

// [套餐卡][关联服务]编辑
export const markserveUpdate = p => post('api/v1.0/marketeinfo/markete_card_serve_update', p)

// [套餐卡][关联服务]移除服务
export const markserveDelete = p => post('api/v1.0/marketeinfo/markete_card_serve_delete', p)

// [套餐卡][关联服务]修改服务数量
export const markserveNum = p => post('api/v1.0/marketeinfo/markete_card_serve_num', p)

// [套餐卡]是否上架
export const markserveIshelf = p => post('api/v1.0/marketeinfo/markete_card_ishelf', p)

// [套餐卡]列表排序下拉查询
export const markdeSortGet = p => post('api/v1.0/marketeinfo/markete_sort_down', p)

// [套餐卡]列表排序修改
export const markdeSortUpdate = p => post('api/v1.0/marketeinfo/markete_sort_update', p)

// [套餐卡][匹配抵扣券]添加
export const markcardveAdd = p => post('api/v1.0/marketeinfo/markete_card_as_ded_add', p)

// [套餐卡][匹配抵扣券]添加查询
export const markcardadGet = p => post('api/v1.0/marketeinfo/markete_card_as_ded_add_get', p)

// [套餐卡][匹配抵扣券]移除
export const markcardveDelete = p => post('api/v1.0/marketeinfo/markete_card_as_ded_delete', p)

// [套餐卡][匹配抵扣券]查询-new
/* export const markcardveGet = p => post('api/v1.0/marketeinfo/markete_card_as_ded_get', p) */
export const markcardveGet = p => post('api/v2.0/marketeinfo/markete_card_as_ded_get', p)

// [套餐卡][匹配抵扣券]赠送方式-提交
export const markdeChooseUpdate = p => post('api/v1.0/marketeinfo/markete_ded_choose_update', p)

// [套餐卡][匹配抵扣券]赠送方式选择数查询
export const markdeChooseGet = p => post('api/v1.0/marketeinfo/markete_ded_choose_get', p)

// [套餐卡][匹配抵扣券]赠送方式下拉框
export const couChooseDown = p => post('api/v1.0/marketeinfo/markete_ded_choose_down', p)

// [套餐卡][匹配抵扣券]保存配置数量
export const couSaveConNum = p => post('api/v2.0/marketeinfo/markete_card_as_ded_num', p)

/* 代金券 */
// [代金券]列表查询
export const markkimGet = p => post('api/v1.0/marketeinfo/markete_kim_get', p)

// [代金券]添加
export const markkimAdd = p => post('api/v1.0/marketeinfo/markete_kim_add', p)

// [代金券]编辑
export const markkimUpdate = p => post('api/v1.0/marketeinfo/markete_kim_update', p)

// [代金券]删除
export const markkimDelete = p => post('api/v1.0/marketeinfo/markete_kim_delete', p)

// [代金券]详情查询
export const markkimDetail = p => post('api/v1.0/marketeinfo/markete_kim_get_id', p)

// [代金券]上下架
export const markkimOper = p => post('api/v1.0/marketeinfo/markete_kim_operate', p)

// [代金券]关联门店绑定
export const markkimShopID = p => post('api/v1.0/marketeinfo/markete_kim_shop_update', p)

// [代金券]关联门店查询
export const markkimShopGet = p => post('api/v1.0/marketeinfo/markete_kim_shop_get', p)

// [代金券]关联服务绑定
export const markkimSerID = p => post('api/v1.0/marketeinfo/markete_kim_serve_update', p)

// [代金券]关联服务查询
export const markkimServe = p => post('api/v1.0/marketeinfo/markete_kim_as_serve_get', p)

// [代金券]关联服务添加
export const markkimSerAdd = p => post('api/v1.0/marketeinfo/markete_kim_as_serve_add', p)

// [代金券]关联服务添加查询
export const markkimSerGet = p => post('api/v1.0/marketeinfo/markete_kim_as_serve_add_get', p)

// [代金券]关联服务移除
export const markkimSerDelete = p => post('api/v1.0/marketeinfo/markete_kim_as_serve_delete', p)

/* 抵扣券 */
// [抵扣券]添加
export const markdeAdd = p => post('api/v1.0/marketeinfo/markete_deduction_add', p)

// [抵扣券]编辑
export const markdeEdit = p => post('api/v1.0/marketeinfo/markete_deduction_update', p)

// [抵扣券]删除
export const markdeDelete = p => post('api/v1.0/marketeinfo/markete_deduction_delete', p)

// [抵扣券]列表查询
export const markdeGet = p => post('api/v1.0/marketeinfo/markete_deduction_get', p)

// [抵扣券]上下架
export const markdeState = p => post('api/v1.0/marketeinfo/markete_deduction_mdstate', p)

// [抵扣券]详情查询
export const markdeGetId = p => post('api/v1.0/marketeinfo/markete_deduction_get_id', p)

// [抵扣券]关联门店查询
export const markdeShop = p => post('api/v1.0/marketeinfo/markete_deduction_shop_get', p)

// [抵扣券]关联门店绑定
export const markdeShopID = p => post('api/v1.0/marketeinfo/markete_deduction_shop_update', p)

/* // [抵扣券][关联服务]查询
export const markdeServe = p => post('api/v1.0/marketeinfo/markete_deduction_serve_get', p)

// [抵扣券][关联服务]绑定
export const markdeServeID = p => post('api/v1.0/marketeinfo/markete_deduction_serve_update', p) */

// [抵扣券][关联服务]添加查询
export const markdeServeQuery = p => post('api/v1.0/marketeinfo/markete_ded_as_serve_add_get', p)

// [抵扣券][关联服务]添加
export const markdeServeAdd = p => post('api/v1.0/marketeinfo/markete_ded_as_serve_add', p)

// [抵扣券][关联服务]移除
export const markdeServeDel = p => post('api/v1.0/marketeinfo/markete_ded_as_serve_delete', p)

// [抵扣券][关联联服务]查询
export const markdeServeList = p => post('api/v1.0/marketeinfo/markete_ded_as_serve_get', p)
