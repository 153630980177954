<template>
  <div class="specialForm">
    <div class="specialFL">
      <div class="sflName" :class="serveFormType === '0' ? 'sfActive': ''" @click="changeForm('0')">基本信息</div>
      <div class="sflName"  v-if="serveInfoEdit !== null" :class="serveFormType === '1' ? 'sfActive': ''" @click="changeForm('1')">配套抵扣券</div>
    </div>
    <div class="specialFR">
      <!-- 基本信息 -->
      <div class="formItemType" v-if="serveFormType === '0'">
        <el-form class="settingInfoBox" :model="rechInfo" :rules="rechInfoRule" ref="rechInfoForm">
          <div class="basicClass">
            <p class="formTitle">基本信息</p>
            <div class="flexCB">
              <el-form-item label="充值产品名称" class="detailFormItem" prop="mtname">
                <el-input v-model="rechInfo.mtname"  placeholder="请输入充值产品名称" maxlength="20"></el-input>
              </el-form-item>
              <el-form-item label="充值金额" class="detailFormItem" prop="mtcmoney">
                <el-input v-model="rechInfo.mtcmoney"  placeholder="请输入充值金额" maxlength="10"></el-input>
              </el-form-item>
            </div>
            <div class="flexCB">
              <el-form-item label="充值赠送金额" class="detailFormItem" prop="mtzmoney">
                <el-input v-model="rechInfo.mtzmoney"  placeholder="请输入充值赠送金额" maxlength="10"></el-input>
              </el-form-item>
              <el-form-item label="绩效提成" class="detailFormItem" prop="mtdmoney">
                <el-input v-model="rechInfo.mtdmoney"  placeholder="请输入绩效提成" maxlength="10"></el-input>
              </el-form-item>
            </div>
            <!-- <el-form-item label="抵扣券领取方式" class="detailFormItem" prop="mdpayway">
              <el-select v-model="rechInfo.mdpayway" placeholder="请选择发放方式" style="width: 100%;">
                <el-option v-for="item in mdpaywayList" :key="item.index" :label="item.sysvalue" :value="item.sysid"></el-option>
              </el-select>
            </el-form-item> -->
          </div>
        </el-form>
        <!--  提交按钮  -->
        <div class="flexCE" style="position: absolute;bottom: 20px;right: 50px;">
          <div class="settingCancel" @click="infoCancel">取消</div>
          <div class="settingConfirm" @click="infoConfirm">确定</div>
        </div>
      </div>
      <!-- 配套抵扣券 -->
      <div class="formItemType" v-if="serveFormType === '1'">
        <RechList :serveInfoEdit="serveInfoEdit" @serCancel="serCancel" @serSuccess="serSuccess"></RechList>
      </div>
    </div>
  </div>
</template>

<script>
import { marketAdd, marketUpdate, marketGetAll } from '@/api/market.js'
import { sysGet } from '@/api/system.js'
import RechList from '@/components/marketing/rechList'
export default {
  components: {
    RechList
  },
  props: ['serveInfoEdit'],
  data () {
    const moneyReg = (rule,value,callback) => {
    if(value.length > 10){
        callback(new Error('长度在 0 到 10 个字符'));
    }else{
        setTimeout(() => {
          if (!/^\d+[.]?\d{0,2}$/.test(value) && value){
            callback(new Error('请输入正整数或小数保留两位小数'));
          }else{
            callback()
          }
        }, 500);
      }
    };
    return {
      serveFormType: '0',
      rechInfo: {
        mtid: '',
        mtname: '',
        mtcmoney: '',
        mtzmoney: '',
        mtdmoney: '',
        mdpayway: ''
      },
      rechInfoRule: {
        mtname: [
          { required: true, trigger: 'blur', message: '充值产品名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        mtcmoney: [
          { required: true, trigger: 'blur', message: '充值金额不能为空' },
          { validator: moneyReg, trigger: 'blur' }
        ],
        mtzmoney: [
          { required: true, trigger: 'blur', message: '充值赠送金额不能为空' },
          { validator: moneyReg, trigger: 'blur' }
        ],
        mtdmoney: [
          { required: true, trigger: 'blur', message: '绩效提成不能为空' },
          { validator: moneyReg, trigger: 'blur' }
        ],
        // mdpayway: [
        //   { required: true, trigger: 'blur', message: '绩效提成不能为空' }
        // ]
      },
      mdpaywayList: []
    }
  },
  created () {
    this.getInit()
  },
  methods: {
    getInit () {
      // 查询
      if (this.serveInfoEdit !== null) {
        marketGetAll({ mtid: this.serveInfoEdit.mtid }).then(res => {
          if (res.status === 1) {
            this.rechInfo = res.data.data[0]
          }
        })
      }
      // 抵扣券发放方式
      // sysGet({ systype: 'mtchoose' }).then(res => {
      //   if (res.status === 1) {
      //     this.mdpaywayList = res.data.data
      //   } else {
      //     this.mdpaywayList = []
      //   }
      // })
    },
    changeForm (type) {
      this.serveFormType = type
      if (type === '0') { //基本信息
        if (this.serveInfoEdit !== null) {
          this.getInit()
        }
      }
    },
    // 基本信息取消
    infoCancel () {
      this.$emit('formCancel')
    },
    // 基本信息确定
    infoConfirm () {
      this.$refs.rechInfoForm.validate((valid) => {
        if (!valid) return
        const params = {
          mtid: this.rechInfo.mtid,
          mtname: this.rechInfo.mtname,
          mtcmoney: this.rechInfo.mtcmoney,
          mtzmoney: this.rechInfo.mtzmoney,
          mtdmoney: this.rechInfo.mtdmoney,
          // mdpayway: this.rechInfo.mdpayway
        }
        if (this.serveInfoEdit === null) { // 新增提交
          marketAdd(params).then(res => {
            if (res.status === 1) {
              this.$alert('新增充值设置基本信息成功，请尽快完善“配套抵扣券” 信息', '保存成功', {
                confirmButtonText: '确定',
                callback: action => {
                  this.$emit('formSuccess')
                }
              })
            } else {
              this.$message.error(res.message)
            }
          })
        } else { // 编辑提交
          marketUpdate(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.$emit('formSuccess')
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 配套抵扣券取消
    serCancel () {
      this.$emit('formCancel')
    },
    // 配套抵扣券确定
    serSuccess () {
      this.$emit('formSuccess')
    },
  }
}
</script>

<style scoped>

</style>
