<template>
<!-- 充值设置 -->
  <div class="box100">
    <div class="whiteBg" v-if="!showForm">
      <template v-if="roleBtn[0].power">
        <div class="el-icon-plus settingAdd" @click="noticeAdd"><span> 新增</span></div>
      </template>
      <div class="table_list" style="margin-top: 16px;">
        <el-table
          :header-cell-style="{background:'#EBF2FE'}"
          :data="rechargeList">
          <el-table-column
            label="序号"
            type="index"
            width="100">
          </el-table-column>
          <el-table-column
            label="充值产品名称"
            prop="mtname">
          </el-table-column>
          <el-table-column
            label="充值金额"
            prop="mtcmoney">
          </el-table-column>
          <el-table-column
            label="充值赠送金额"
            prop="mtzmoney">
          </el-table-column>
          <el-table-column
            label="排序">
            <template slot-scope="scope" v-if="roleBtn[3].power">
              <el-button @click="sortHand(scope.row, 1)" type="primary" size="mini" plain
                  :disabled="scope.$index === 0" style="display: block">
                上移<i class="el-icon-caret-top el-icon--right"></i>
              </el-button>
              <el-button @click="sortHand(scope.row, 2)" type="primary" size="mini" plain
                  :disabled="scope.$index === rechargeList.length-1" style="display: block;margin-left:0px;margin-top: 5px;">
                下移<i class="el-icon-caret-bottom el-icon--right"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            label="展示"
            prop="mtisok">
            <template slot-scope="scope">
              <el-switch
                :value="scope.row.mtisok"
                active-color="#13ce66"
                inactive-color="#A5B7CC"
                @change="changeSwich(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            width="100"
            label="操作">
            <template slot-scope="scope">
              <template v-if="roleBtn[2].power">
                <el-button type="text" size="small" @click="showEdit(scope.row)">编辑</el-button>
              </template>
              <template v-if="roleBtn[1].power">
                <el-button type="text" size="small" style="color: red;" @click="handDel(scope.row)">删除</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="pageChange"
        :current-page="page"
        :page-sizes="[10]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="settingPage">
      </el-pagination>
    </div>
    <div class="box100" v-if="showForm">
      <RechForm :serveInfoEdit="serveInfoEdit" @formCancel="formCancel" @formSuccess="formSuccess"></RechForm>
    </div>
  </div>
</template>

<script>
import RechForm from '@/components/marketing/rechForm'
import { marketGet, marketDelete, marketOper, marketIsOK } from '@/api/market.js'
export default {
  name: 'recharge',
  components: {
    RechForm
  },
  props: ['roleBtn'],
  data () {
    const moneyReg = (rule, value, callback) => {
      if (value.length > 10) {
        callback(new Error('长度在 0 到 10 个字符'))
      } else {
        setTimeout(() => {
          if (!/^\d+[.]?\d{0,2}$/.test(value) && value) {
            callback(new Error('请输入正整数或小数保留两位小数'))
          } else {
            callback()
          }
        })
      }
    }
    return {
      page: 1,
      size: 10,
      total: 0,
      rechargeList: [],
      showForm: false,
      serveInfoEdit: {},
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size
      }
      marketGet(params).then(res => {
        if (res.status === 1) {
          this.rechargeList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 新增展示
    noticeAdd () {
      this.showForm = true
      this.serveInfoEdit = null
    },
    // 编辑展示
    showEdit (row) {
      this.serveInfoEdit = { ...row }
      this.showForm = true
    },
    // 取消表单提交
    formCancel () {
      this.showForm = false
    },
    // 表单提交成功
    formSuccess () {
      this.showForm = false
      this.init()
    },
    // 排序移动
    sortHand (row, type) {
      marketOper({ mtid: row.mtid, oper: type }).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.init()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    // 是否展示
    changeSwich (row) {
      this.$confirm(`确定执行${!row.mtisok ? "展示" : "不展示"}操作, 是否继续？`, "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        const params = {
          mtid: row.mtid,
          mtisok: row.mtisok,
        }
        marketIsOK(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // 删除
    handDel (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        marketDelete({ mtid: row.mtid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>
