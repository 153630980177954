<template>
  <!-- 充置  配套抵扣券 -->
  <div class="box100">
    <!-- 一级列表 -->
    <div v-if="!showform">
      <div class="settingAdd serveadd" @click="handleAdd"><span>添加抵扣券</span></div>
      <div class="flexCB ser_bg" style="margin-bottom: 16px;" v-if="this.reOneList.length !== 0">
        <div>赠送方式: {{ configLabel }}</div>
        <div style="color:#3C84EF;cursor: pointer;" @click="changeEdit">修改</div>
      </div>
      <div class="table_list">
        <el-table
          class="couTable"
          :data="reOneList"
          :header-cell-style="{background:'#EBF2FE'}">
          <el-table-column
            label="序号"
            type="index"
            width="50">
          </el-table-column>
          <el-table-column
            label="抵扣券名称"
            prop="mdname">
          </el-table-column>
          <el-table-column
            label="优惠内容"
            prop="mdmark"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="ishelf"
            label="上架状态">
            <template slot-scope="scope">
              <span v-if="scope.row.mdstate == 1">上架</span>
              <span v-if="scope.row.mdstate == 2">下架</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="num"
            label="数量">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.num" @change="handleChange" :min="1" class="inputnum"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="80">
            <template slot-scope="scope">
              <el-button type="text" size="small"  style="color:#F44C8E;" @click="handleRemove(scope.row)">移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--  提交按钮  -->
      <div class="flexCE" style="position: absolute;bottom: 0px;right: 0px;">
        <div class="settingCancel" @click="infoCancel">返回</div>
         <div class="settingConfirm" v-if="this.reOneList.length !== 0" @click="infoConfirm">保存</div>
      </div>
    </div>

    <!-- 二级列表 -->
    <div v-if="showform">
      <div class="tableList2">
        <el-table
          ref="shopTable"
          :data="reTwoList"
          :header-cell-style="{background:'#EBF2FE'}"
          :row-key="getKeys"
          @row-click="handleRowClick"
          @selection-change="selectionChange">
          <el-table-column
            prop="mtid"
            type="selection"
            :reserve-selection="true">
          </el-table-column>
          <el-table-column
            label="序号"
            type="index"
            width="100">
          </el-table-column>
          <el-table-column
            label="抵扣券名称"
            prop="mdname">
          </el-table-column>
          <el-table-column
            label="优惠内容"
            prop="mdmark">
          </el-table-column>
          <el-table-column
            prop="mdstate"
            label="上架状态">
            <template slot-scope="scope">
              <span v-if="scope.row.mdstate == 1">上架</span>
              <span v-if="scope.row.mdstate == 2">下架</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="pageChange1"
        :current-page="page1"
        :page-sizes="[10]"
        :page-size="size1"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total1"
        class="settingPage">
      </el-pagination>

      <!--  提交按钮  -->
      <div class="flexCE" style="position: absolute;bottom: 0px;right: 0px;">
        <div class="settingCancel" @click="goCancel">取消</div>
        <div class="settingConfirm"  @click="goConfirm">添加</div>
      </div>
    </div>

    <!-- 抵扣券修改赠送方式弹窗 -->
    <el-dialog title="排序" :visible.sync="givShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="givCancel">
      <el-form :model="givForm" :rules="givRule" ref="givLabel">
        <el-form-item label="排序" prop="mtchoice">
          <el-select v-model="givForm.mtchoice" placeholder="下拉选择排序" style="width: 100%;" >
            <el-option v-for="item in givList" :key="item.mtchoice" :label="item.value" :value="item.mtchoice"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="givCancel">取消</div>
        <div class="settingConfirm" @click="givConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { marketveGet, marketveAdd, marketveadGet, marketveDelete, marketReNumGet, marketReNumList, marketReNumEdit, marketNumSave } from '@/api/market.js'
export default {
  props: ['serveInfoEdit'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      reOneList: [], // 一级列表
      multipleSelection: [],
      showform: false,
      page1: 1,
      size1: 10,
      total1: 0,
      reTwoList: [], // 未配置列表
      configLabel: '',
      givList: [],
      givShow: false,
      givForm: { mtchoice: '' },
      givRule: { mtchoice: [{ required: true, trigger: 'change', message: '请选择赠送方式' }] },
    }
  },
  created () {
    this.init()
  },
  methods: {
    /* 配套抵扣券查询 */
    init () {
      const params = {
        mtid: this.serveInfoEdit.mtid,
        page_index: this.page,
        page_size: this.size
      }
      /* 已配置列表 */
      marketveGet(params).then(res => {
        if (res.status === 1) {
          this.reOneList = res.data.data
          this.total = res.data.recordcount
        }
      })
      /* 已配置-赠送方式-数量 */
      marketReNumGet({ mtid: this.serveInfoEdit.mtid }).then(res => {
        if (res.status === 1) {
          this.configLabel = res.data.value
          this.givForm.mtchoice = res.data.mtchoice
        }
      })
    },
    /* 添加抵扣券查询 */
    getInit () {
      const params = {
        mtid: this.serveInfoEdit.mtid,
        page_index: this.page1,
        page_size: this.size1
      }
      marketveadGet(params).then(res => {
        if (res.status === 1) {
          this.reTwoList = res.data.data
          this.total1 = res.data.recordcount
        }
      })
    },
    /* 抵扣券选择 */
    getKeys (row) {
      return row.mtid
    },
    handleRowClick (row, column, event) {
      this.$refs.shopTable.toggleRowSelection(row)
    },
    selectionChange (val) {
      if (val.length === 0) {
        this.multipleSelection = []
      } else {
        this.multipleSelection = val
      }
    },
    /* 添加抵扣券-点击事件 */
    handleAdd () {
      this.showform = true
      this.getInit()
    },
    /* 添加抵扣券-取消-二级返一级 */
    goCancel () {
      this.showform = false
      this.init()
    },
    /* 添加抵扣券-确定新增操作 */
    goConfirm () {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请先选择服务')
      } else {
        const arr = []
        for (let i = 0; i < this.multipleSelection.length; i++) {
          arr.push(this.multipleSelection[i].mdid)
        }
        this.$confirm('请确认您是否要对以上所选的配套抵扣券进行添加', '添加配套抵扣券', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          marketveAdd({ mdid: arr, mtid: this.serveInfoEdit.mtid }).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.init()
              this.showform = false
            } else {
              this.$message.error(res.message)
            }
          })
        }).catch(() => {})
      }
    },
    /* 添加抵扣券-翻页 */
    pageChange1 (page) {
      this.page1 = page
      this.getInit()
    },
    /* 已配置-移除-操作 */
    handleRemove (row) {
      this.$confirm('当前配套抵扣券是否移除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        marketveDelete({ mtadid: row.mtadid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    /* 已配置-返回 */
    infoCancel () {
      this.$emit('serCancel')
    },
    eventDisposalRangeChange (e, val) {
      e.target.value = e.target.value.match(/^\d*(\.?\d{0,2})/g)[0] || null
      val = e.target.value
      if (val === '') {
        this.$message.warning('数量不能为空')
      }
    },
    /* 监听数量变化 */
    handleChange (value) {
      this.reOneList.num = value
    },
    /* 已配置-数量保存-操作 */
    infoConfirm () {
      if (this.checkNum(this.reOneList) === false) {
        this.$message.warning('配置数量不能为空')
      } else {
        const arr = []
        this.reOneList.forEach(item => {
          const obj = { mdid: item.mdid, num: item.num }
          arr.push(obj)
        })
        const params = { mtid: this.serveInfoEdit.mtid, mdid: arr }
        marketNumSave(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          }
        })
      }
    },
    checkNum (arr) {
      let res
      arr.forEach(item => {
        if (item.num === '') { res = false }
      })
      return res
    },
    /* 赠送方式-展示 */
    changeEdit () {
      this.givShow = true
      this.getSelectList()
    },
    /* 赠送方式-下拉列表 */
    getSelectList () {
      marketReNumList({ mtid: this.serveInfoEdit.mtid }).then(res => {
        if (res.status === 1) {
          this.givList = res.data.data
        }
      })
    },
    /* 赠送方式-取消 */
    givCancel () {
      this.givShow = false
      this.$refs.givLabel.resetFields()
    },
    /* 赠送方式-选择提交 */
    givConfirm () {
      this.$refs.givLabel.validate((valid) => {
        if (!valid) return
        const params = {
          mtid: this.serveInfoEdit.mtid,
          mtchoice: this.givForm.mtchoice
        }
        marketReNumEdit(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.givCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.table_list{
  width: 100%;
  height: calc(100% - 160px);
}
.couTable{
  width: 100%;
  height: 100%;
}
.couTable >>>.el-table__body-wrapper{
  overflow-y: scroll;
  max-height: calc(100vh - 390px);
  scrollbar-width: none;
}
.couTable >>>.el-table__body-wrapper::-webkit-scrollbar{
  width: 0;
}
.couTable::before{
  height: 0;
}
.serveadd {
  margin-bottom: 20px;
}
.inputnum{
  width: 130px;
}
.inputnum >>> .el-input-number .el-input__inner {
  padding-left: 0;
  padding-right: 0;
}
</style>
